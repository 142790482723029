import React from 'react';
import LandingStatistics from './LandingStatistics';
import ReadMoreButton from './atoms/ModalContent';

const img1 = '/images/landing/create_event.svg';
const img2 = '/images/landing/templates_processes.svg';
const img3 = '/images/landing/connect_collaborate.svg';
const img4 = '/images/landing/booking_accomodation.svg';

const features = [
  {
    h2: 'ORGANISER',
    name: 'DIFFERENT EVENT TYPES',
    initialText: <>Choose between 10 different event types.</>,
    expandedText: (
      <>
        As an organizer you can choose between 10 different types of scientific events. Our platform
        empowers you to handle all facets of conference management seamlessly – from setting up
        submission and registration processes to coordinating with committee members and
        communicating with participants. You can edit, cancel, delete scientific events,  invite
        committee members, make decisions on participants and generate program agenda.
      </>
    ),

    img: `${img1}`,
    name1: 'INTUITIVE CONFERENCE MANAGEMENT SYSTEM',
    initialText1: (
      <>
        The Intuitive Conference Management System is designed with a focus on user experience and
        functionality, making the task of managing complex scientific events a breeze.
      </>
    ),
    expandedText1: (
      <>
        Managing a scientific conference has never been easier with our intuitive conference
        management system. Designed to streamline the entire process, we allow organizers to
        efficiently plan, execute, and monitor every aspect of their scientific event.
        <br />
        <br />
        The Intuitive Conference Management System is designed with a focus on user experience and
        functionality, making the task of managing complex scientific events a breeze. Experience
        stress-free event management, leaving you more time to focus on the scientific content and
        networking that make your event truly impactful. Let SciVenia handle the logistics, so you
        can concentrate on what really matters - driving scientific discourse and discovery.
      </>
    ),
  },
  {
    // h2: "",
    name: 'TEMPLATES FOR ALL PROCESSES',
    initialText: (
      <>
        To streamline your workflow and ensure consistency in your scientific event management,
        SciVenia offers a suite of ready-to-use templates for all processes.
      </>
    ),
    expandedText: (
      <>
        To streamline your workflow and ensure consistency in your scientific event management,
        SciVenia offers a suite of ready-to-use templates for all processes. Our intuitive templates
        are designed to minimize time-consuming administrative tasks and remove the guesswork from
        event planning. They serve as a helpful guide, ensuring that all relevant information is
        captured accurately and efficiently. Plus, they are fully customizable, allowing you to
        tailor them to your event's unique needs and branding.
        <br />
        <br />
        With SciVenia's templates, you can ensure a standardized, professional look and feel for all
        your event materials and communications, enhancing the overall experience for your
        attendees.
      </>
    ),
    img: `${img2}`,
    name1: 'VALUABLE INSIGHTS ABOUT RESEARCH ACTIVITIES',
    initialText1: (
      <>
        Harness the power of data with SciVenia. Our platform provides detailed insights about your
        organisation research activities, helping you understand collaborative trends between
        scientists in your organisation, track publication patterns, and gauge research impact.
      </>
    ),
    expandedText1: (
      <>
        Harness the power of data with SciVenia. Our platform provides detailed insights about your
        organization research activities, helping you understand collaborative trends between
        scientists in your organization, track publication patterns, and gauge research impact.
        Unlock the potential of your scientific community, identify growth opportunities, and
        position your organization in a dynamic research landscape.. With SciVenia, turn data into
        your most powerful tool for scientific advancement.
      </>
    ),
  },
  {
    h2: 'SCIENTIST',
    name: 'CONNECTING WITH PEERS AND COLLABORATE',
    initialText: (
      <>
        SciVenia is a hub for scientific connectivity, fostering an ecosystem where collaboration is
        visual, tangible, and impactful. Our platform bridges the gap between scientists, enabling
        you to connect with peers, join forces on research projects, and visualise the dynamic
        landscape of collaborative activities.
      </>
    ),
    expandedText: (
      <>
        SciVenia is a hub for scientific connectivity, fostering an ecosystem where collaboration is
        visual, tangible, and impactful. Our platform bridges the gap between scientists, enabling
        you to connect with peers, join forces on research projects, and visualize the dynamic
        landscape of collaborative activities.
        <br />
        <br />
        Here, you can track your collaborations, view interaction patterns, and identify potential
        new partners. You can also explore the collaborative networks of fellow scientists,
        fostering a deeper understanding of the global scientific community. At SciVenia, we believe
        that every connection holds the potential for the next groundbreaking discovery.
      </>
    ),
    img: `${img3}`,
    name1: 'SCIENTIFIC PROFILE',
    initialText1: (
      <>
        Your Scientist Profile is more than just a resume: it's a living, interactive portfolio of
        your scientific journey. Showcase your publications, share your ongoing projects, highlight
        your areas of expertise, and express your research interests!
      </>
    ),
    expandedText1: (
      <>
        Your Scientist Profile is more than just a resume; it's a living, interactive portfolio of
        your scientific journey. Showcase your publications, share your ongoing projects, highlight
        your areas of expertise, and express your research interests!
        <br />
        <br />
        Your profile acts as a hub for connectivity, enabling you to engage with peers, participate
        in scientific discussions, and explore collaborative opportunities. Stay visible, stay
        connected, and let the world discover your scientific prowess.
      </>
    ),
  },
  {
    h2: '',
    name: 'BOOKING TRAVEL AND ACCOMODATION',
    initialText: (
      <>
        At SciVenia, we believe that attending a scientific event should be hassle-free, leaving you
        more time to focus on what truly matters – your research and collaboration.
        <br />
        That's why we offer a streamlined process for booking travel and accommodation directly
        through our platform.
      </>
    ),
    expandedText: (
      <>
        At SciVenia, we believe that attending a scientific event should be hassle-free, leaving you
        more time to focus on what truly matters – your research and collaboration. That's why we
        offer a streamlined process for booking travel and accommodation directly through our
        platform. As a participant looking to secure comfortable accommodation near the event
        location, we have got you covered. Our platform is integrated with reliable travel and
        accommodation services, providing a range of options that cater to different needs and
        budgets.
        <br />
        <br />
        Take the stress out of planning with SciVenia. Book your travel and accommodation with ease,
        and focus on making the most out of your scientific events. Experience convenience at its
        best with SciVenia!
      </>
    ),
    img: `${img4}`,
    name1: 'Become a SCIENTIFIC Influencer',
    initialText1: (
      <>
        At SciVenia, we empower you to rise beyond the traditional role of a scientist. Step into
        the spotlight as a Scientific Influencer, become a leader in your field, and shape society
        with your insights and expertise.
      </>
    ),
    expandedText1: (
      <>
        At SciVenia, we empower you to rise beyond the traditional role of a scientist. Step into
        the spotlight as a Scientific Influencer, become a leader in your field, and shape society
        with your insights and expertise. It's time to change lives, shape policies, and lead
        society towards a brighter, more informed future. Join SciVenia today and lead the
        scientific revolution by creating a world that's guided by knowledge and innovation. Your
        journey from science to society starts here!
      </>
    ),
  },
];

export default function LandingFeatures() {
  return (
    <div id='features'>
      <section className="py-5">
        <LandingStatistics name="" value="FEATURES" />
        <div className="overflow-hidden py-1 sm:py-2 bg-side mb-24">
          <div className="bg-wave-bottom" />
          <div className="bg-side-right" />
          <div className="overflow-hidden py-1 sm:py-2 z-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid place-items-center">
                {features.map((feature, index) => (
                  <div
                    key={index}
                    className={`mx-auto grid place-items-center pt-10 pb-6 grid-cols-1 gap-x-20 gap-y-16 sm:gap-y-20 lg:grid-cols-2 ${
                      index % 2 === 1 ? 'lg:flex-row-reverse' : ''
                    }`}
                  >
                    <div className={`lg:pt-4 ${index % 2 === 1 ? 'lg:order-2' : ''}`}>
                      <div className="px-0 py-4 my-8 sm:my-10">
                        <h2 className="subtitle text-white text-lg font-normal leading-10 -mt-20 mb-10">
                          {feature.h2}

                          {index % 2 === 0 && <p className="titleTeamBorder" />}
                        </h2>

                        <p className="text-white text-lg sm:text-[1.75rem] font-normal leading-10 uppercase">
                          {' '}
                          {feature.name}{' '}
                        </p>

                        <ReadMoreButton
                          name={feature.name}
                          initialText={feature.initialText}
                          expandedText={feature.expandedText}
                        />
                      </div>
                      <div className="px-0 py-4 my-8 sm:my-10">
                        <p className="text-white text-lg sm:text-[1.75rem] font-normal leading-10 uppercase">
                          {' '}
                          {feature.name1}{' '}
                        </p>

                        <ReadMoreButton
                          name={feature.name1}
                          initialText={feature.initialText1}
                          expandedText={feature.expandedText1}
                        />
                      </div>
                    </div>
                    <div className={`flex ${index % 2 === 1 ? 'lg:order-1' : ''}`}>
                      <img
                        src={feature.img}
                        alt="Product screenshot"
                        className=""
                        width={2432}
                        height={1442}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

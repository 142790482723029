import React, { useState } from 'react';
import LandingStatistics from './LandingStatistics';
import ReadMoreButton from './atoms/ModalContent';

const features = [
  {
    name: 'Open science',
    initialText: (
      <>
        We promote open science practices by encouraging scientists and event organisers to share
        their research with everyone on our platform.
      </>
    ),
    expandedText: (
      <>
        We aim to:
        <br />
        <br />
        Empower scientists to share their ground-breaking research without any cost
        <br />
        <br />
        Remove administrative barriers and enable open unrestricted dissemination of scientific
        knowledge.
        <br />
        <br />
        Reshape the future of science: democratise scientific discovery together!
      </>
    ),
  },
  {
    name: 'Research transparency',
    initialText: (
      <>
        By implementing open reviews practices, SciVenia increases scientific research transparency
        and allows for scientific reviews evaluation.
      </>
    ),
    expandedText: (
      <>
        We push for:
        <br />
        <br />
        Elevating the level of transparency in scientific research and facilitating a comprehensive
        evaluation of scientific reviews.
        <br />
        <br />
        Research transparency that proves our commitment to a more accountable and accessible
        scientific landscape.
        <br />
        <br />
        Enhancing the credibility of research and strengthen trust in scientific findings.
      </>
    ),
  },
  {
    name: 'Open innovation',
    initialText: (
      <>
        Fostering an environment where scientists, researchers, and industry from various fields can
        contribute and collaborate.
      </>
    ),
    expandedText: (
      <>
        Open Innovation allows us to leverage collective intelligence, fostering an environment
        where scientists, researchers, and industry from various fields can contribute, cooperate,
        and build upon each other's work.
        <br />
        This openness between academia and industry sparks creativity, accelerates problem-solving,
        and drives innovation at a pace and scale previously unimaginable.
      </>
    ),
  },
  {
    name: 'Building a community',
    initialText: (
      <>
        At SciVenia, we aim to bring scientists together and facilitate their collaboration after
        scientific events.
      </>
    ),
    expandedText: (
      <>
        At SciVenia, we aim to bring scientists together. In the era of digital communication, we
        promote scientists as influencers who have the power to shape opinions, stimulate dialogue,
        and influence trends in the scientific community and beyond. By transforming their
        discoveries, research findings, and academic knowledge into compelling content, scientists
        can drive positive social activities and inspire learning.
      </>
    ),
  },
];

export default function LandingValues() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div id='concepts'>
      <section className="py-5">
        <LandingStatistics name="" value="CONCEPTS" />

        <div className="relative">
          <div className="valuesLine" />

          <div className="z-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-20">
              <div className="gridValues grid grid-cols-1 gap-x-20 lg:grid-cols-2">
                {features.map((feature, index) => (
                  <div
                    key={index}
                    className={`mx-auto grid place-items-center gap-x-20 gap-y-16 sm:gap-y-20 `}
                  >
                    <div className="flex">
                      <div className="px-0 py-4 my-8 sm:my-12">
                        <p className="text-white font-lato text-lg sm:text-[1.75rem] font-normal leading-110 uppercase">
                          {' '}
                          {feature.name}{' '}
                        </p>
                        <ReadMoreButton
                          name={feature.name}
                          initialText={feature.initialText}
                          expandedText={feature.expandedText}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

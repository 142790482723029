import React from 'react';
import LandingHeader from './components/LandingHeader';
import LandingCaption from './components/LandingCaption';
import LandingValues from './components/LandingValues';
import LandingTeam from './components/LandingTeam';
import LandingFeatures from './components/LandingFeatures';
import LandingPackages from './components/LandingPackages';
import LandingFooter from './components/LandingFooter';
import 'tailwindcss/tailwind.css';
import LandingGradient from './components/LandingGradient';

function LandingPage() {
  return (
    <div className="App" id="landing-page">
      <LandingHeader />
      <div>
        <LandingCaption />
        <LandingValues />
        <LandingGradient />
        <LandingFeatures />
        <LandingTeam />
        <LandingPackages />
        <LandingFooter />
      </div>
    </div>
  );
}

export default LandingPage;

import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content ">
                <div className="mx-auto max-w-[76rem] py-10">
                    <div className="modal-button-text flex">
                        <p></p>
                        <button className="close-button" onClick={onClose}>
                            X
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
import React from "react";
import Link from "@app/components/atoms/Link";

export default function Card({ imageUrl, title, description, buttonText, buttonUrl, text, backgroundColor, openVideoModal }) {
    return (
        <>
            <div className="cardProject">
                <div className="">
                    <div className="mx-auto grid place-items-center overflow-hidden grid-cols-1  lg:grid-cols-2">
                        <div className="">
                            <div className="">
                                <p className="sliderTitle leading-[4.5rem] font-bold">
                                    {title}
                                </p>
                                <p className="sliderSubTitle my-6 font-light leading-10">
                                    {text}
                                </p>
                                <div className="mt-10 hidden md:flex items-start gap-x-6  sm:pb-5 pb-4">
                                    <Link href="login" naked target="_blank">
                                        <button
                                            type="button"
                                            className="buttonGetStarted "
                                        >
                                            GET STARTED
                                        </button>
                                    </Link>

                                    <button
                                        type="button"
                                        className="buttonHover"
                                        onClick={openVideoModal}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 15 14" fill="none">
                                            <path d="M2.91599 2.24968V12.7497C2.91633 12.856 2.94565 12.9602 3.0008 13.0511C3.05595 13.142 3.13484 13.2161 3.22898 13.2655C3.32313 13.3148 3.42896 13.3376 3.53508 13.3313C3.6412 13.325 3.74359 13.2899 3.83124 13.2298L11.4146 7.97977C11.729 7.76218 11.729 7.23835 11.4146 7.02018L3.83124 1.77018C3.74378 1.70941 3.64133 1.67378 3.53503 1.66715C3.42873 1.66051 3.32265 1.68314 3.22831 1.73257C3.13396 1.78199 3.05497 1.85633 2.99991 1.9475C2.94485 2.03866 2.91583 2.14318 2.91599 2.24968Z" fill="white" />
                                        </svg>
                                        <span className="px-2">WATCH VIDEO </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <img
                            src={imageUrl}
                            alt="Product screenshot"
                            className="w-[30rem] sm:w-[40rem]"
                            width={2432}
                            height={1442}
                        />
                        <div className="mt-2 flex md:hidden items-start gap-x-6  sm:pb-5 pb-4">
                            <Link href="login" naked target="_blank">
                                <button
                                    type="button"
                                    className="buttonGetStarted "
                                >
                                    GET STARTED
                                </button>
                            </Link>

                            <button
                                type="button"
                                className="buttonHover"
                                onClick={openVideoModal}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 15 14" fill="none">
                                    <path d="M2.91599 2.24968V12.7497C2.91633 12.856 2.94565 12.9602 3.0008 13.0511C3.05595 13.142 3.13484 13.2161 3.22898 13.2655C3.32313 13.3148 3.42896 13.3376 3.53508 13.3313C3.6412 13.325 3.74359 13.2899 3.83124 13.2298L11.4146 7.97977C11.729 7.76218 11.729 7.23835 11.4146 7.02018L3.83124 1.77018C3.74378 1.70941 3.64133 1.67378 3.53503 1.66715C3.42873 1.66051 3.32265 1.68314 3.22831 1.73257C3.13396 1.78199 3.05497 1.85633 2.99991 1.9475C2.94485 2.03866 2.91583 2.14318 2.91599 2.24968Z" fill="white" />
                                </svg>
                                <span className="px-2">WATCH VIDEO </span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}